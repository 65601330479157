/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";
import { BsFillXCircleFill } from "react-icons/bs";


// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard Materail-UI example components
import Table from "examples/Tables/Table";

// Data
import data from "layouts/dashboard/components/Projects/data";

function Projects({ systemData, date }) {
  
  const { columns, rows } = data(systemData);
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox width="100%" display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox mb="auto">
          <VuiBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            System Overview
          </VuiTypography>
          </VuiBox>
          {systemData && systemData.Online ? (
        <VuiBox display="flex" alignItems="center" lineHeight={0}>
          <BsCheckCircleFill color="green" size="15px" />
          <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
            &nbsp;<strong>Online</strong> and Operational  
          </VuiTypography>
        </VuiBox>
      ) : (
        <VuiBox display="flex" alignItems="center" lineHeight={0}>
          {/* Render alternative content when Online is false */}
          <BsFillXCircleFill color="rgb(168 46 22)" size="15px" />
          <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
            &nbsp;Offline  
          </VuiTypography>
        </VuiBox>
      )}
         
        </VuiBox>
        <VuiBox color="text" px={2}>

        <div>
         
          </div>
          
        </VuiBox>
        {renderMenu}
      </VuiBox>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} />
        <VuiBox sx={{marginTop: "20px"}}>
        <VuiTypography variant="button" fontWeight="bold" color="text" ml="5px">
            Last Update: 
          </VuiTypography>
          <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
             {date}
          </VuiTypography>
          </VuiBox>
        
      </VuiBox>
      
    </Card>
  );
}

export default Projects;
