/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import React, { useState } from 'react';

import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import VuiButton from "components/VuiButton";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import '../../tailwind.css';

import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Cookies from "js-cookie";







function Tables() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [postData, setPostData] = useState({}); // State to hold post data

  const sensors = [
        { name: 'IMEI', code: 'imei' },
        { name: 'Air Temperature', code: 'airtemp' },
        { name: 'Air Humidity', code: 'airhum' },
        { name: 'Wind Speed', code: 'windspeed' },
        { name: 'Wind Direction', code: 'winddirection' },
        { name: 'Pressure', code: 'atmopres' },
        { name: 'Rain Amont', code: 'rainamount' },
        { name: 'Solar Iraddiation', code: 'solarrad' },
        { name: 'Soil Humidty 1', code: 'soilhum1' },
        { name: 'Soil Humidty 2', code: 'soilhum2' },
        { name: 'Soil Temperature 1', code: 'soiltemp1' },
        { name: 'Soil Temperature 2', code: 'soiltemp2' },
        { name: 'Date', code: 'datetime' },
    ];
  const [selectedSensors, setSelectedSensors] = useState(sensors);
  const [dates, setDates] = useState(null);

  const [items, setItems] = useState([
    { code: '001', name: 'Product A', category: 'Category A', quantity: 10 },
    { code: '002', name: 'Product B', category: 'Category B', quantity: 15 },
    { code: '003', name: 'Product C', category: 'Category A', quantity: 20 },
    { code: '004', name: 'Product D', category: 'Category C', quantity: 8 },
  ]);


 
  console.log(selectedSensors, dates)

  const handleLogout = () => {
    // Delete the "sessionID" cookie
    Cookies.remove("sessionID");
    Cookies.remove("connect.sid");
    fetch('/api/logout')
    .then(response => response.json())
    .then(data => {
      console.log(data.message);
      // Perform any additional logic after logout, e.g., redirecting the user
      window.location.href = '/login'; // Redirect to login page or home page
    })
    .catch(error => {
      console.error('Error:', error);
    });

    // Reload the page
    window.location.reload();
  };

  function convertArrayToCSV(data) {
    const headers = Object.keys(data[0]);
    const csv = [
        headers.join(','),
        ...data.map(item => headers.map(header => item[header]).join(','))
    ].join('\n');
    return csv;
  }
  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
}
  function downloadCSV() {
    
    const csv = convertArrayToCSV(postData.data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `meteoraExport${getFormattedDate()}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    console.log("CSV")
  }

  const handlePostRequest = async () => {
    const url = 'https://meteorastation.com/api/export-db'; // Replace with your API endpoint
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        imei: '[]',
        startDate: dates[0],
        endDate: dates[1],
        fields: selectedSensors
      }), // Replace with your JSON data to post
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPostData(data); // Update state with response data if needed
    } catch (error) {
      console.error('There was an error!', error);
    } }


  return (
    <PageLayout>
      <VuiBox paddingBlock="30px" display="flex" justifyContent="space-between" paddingInline="5%">
      <VuiBox display="flex" justifyContent="center" alignItems="center" >
      <Calendar placeholder="Select Date Range" value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection />

      <MultiSelect value={selectedSensors} onChange={(e) => setSelectedSensors(e.value)} options={sensors} optionLabel="name" 
                placeholder="Select Sensors" maxSelectedLabels={3} className="selDr" />
     <VuiButton
            sx={{width: '150px', marginLeft: "15px"}}
            component="button"
            href=""
            target="_blank"
            rel="noreferrer"
            onClick={downloadCSV}
            variant="gradient"
            color="success"
            
          >
            Export CSV
        </VuiButton>
        <VuiButton
            sx={{width: '200px', marginLeft: "15px"}}
            component="button"
            href=""
            target="_blank"
            rel="noreferrer"
            onClick={handlePostRequest}
            variant="gradient"
            color="warning"
            
          >
            Update Tables
        </VuiButton>
      </VuiBox>
          <VuiButton
            width="100px"
            component="a"
            href=""
            target="_blank"
            rel="noreferrer"
            onClick={handleLogout}
            variant="gradient"
            color="info"
            
          >
            Log out
        </VuiButton>
      </VuiBox>
      <VuiBox paddingInline="5%" py={3}>
        <VuiBox mb={3}>
        <DataTable paginator rows={20}  value={postData.data}  tableStyle={{ minWidth: '50rem' }}>
                {selectedSensors.map((col, i) => (
                    <Column key={col.code} field={col.code} header={col.name} />
                ))}
            </DataTable>
          
        </VuiBox>
      </VuiBox>
      <VuiBox display="flex" justifyContent="center" paddingBottom="40px">
      <Footer />
      </VuiBox>
    </PageLayout>
  );
}

export default Tables;
